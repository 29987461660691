import React from "react";

const HomePage = () => {
  return (
    <>
      <h1>Hello Welcome to my blog</h1>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus
        iure ab explicabo assumenda velit praesentium pariatur veniam facere
        nam? Cupiditate optio, amet in doloribus aperiam non minus voluptatem
        ducimus consectetur perferendis vitae! Labore laboriosam ex saepe
        corrupti nemo perspiciatis. Cum labore ea et tempore maiores suscipit!
        Optio ipsam harum reprehenderit cum ut eaque omnis error excepturi, odio
        deleniti quibusdam consequatur maxime libero, dicta dolore natus quasi
        quos possimus doloremque distinctio recusandae corrupti? Pariatur
        doloremque, itaque quo assumenda corrupti, culpa saepe quod molestias
        similique aperiam laudantium, vero voluptatum magnam vel ipsa eligendi
        laboriosam a alias sit distinctio fugiat! Asperiores, provident!
        Perferendis fugit facere officia molestias? Optio, magnam quo temporibus
        et delectus, iure facere pariatur velit qui neque id! At quae, accusamus
        ea accusantium recusandae temporibus debitis excepturi asperiores
        dolorem. Inventore saepe unde, velit, ipsum rem nemo possimus aut
        dignissimos natus minima corrupti vero quod placeat iure commodi iste
        reiciendis, rerum numquam quos officiis eveniet soluta! Tenetur
        architecto culpa accusamus qui voluptatem ab ducimus corporis quisquam,
        quidem assumenda quae, quaerat odit nam esse ut aliquid error itaque in
        et? Quasi consequatur mollitia fugiat, distinctio consequuntur eos
        corrupti assumenda molestiae incidunt perferendis magnam eaque earum
        veritatis, deserunt officia. Rem minima debitis cumque est!
      </p>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor autem
        similique sunt fugiat tempore nesciunt sequi aliquid, exercitationem
        incidunt, molestiae velit neque, quas possimus quis labore. Architecto,
        excepturi qui dicta cumque ducimus perferendis itaque maiores
        exercitationem magni tempora. Ratione excepturi, tempore quas voluptates
        eius atque. Deserunt, expedita quod minus quam consectetur nulla saepe
        illo doloribus incidunt commodi porro eum asperiores! Fugit sapiente
        voluptas odio quas, iure quia temporibus velit, corporis voluptatem
        veritatis perspiciatis exercitationem accusamus amet sint eaque quod
        dolores laboriosam id sit cumque? Consectetur facilis voluptatum
        commodi, suscipit quod veniam minima! Dolor, magnam nulla itaque quo,
        eaque et, suscipit labore aperiam sint dignissimos placeat aliquid
        asperiores delectus inventore veritatis ducimus mollitia ratione tenetur
        sit consectetur! Quaerat numquam sit libero possimus aspernatur rem
        cumque sed maxime facilis commodi! Quod modi nostrum quo quaerat nihil?
        Blanditiis inventore quaerat quae magnam ut eveniet explicabo illo,
        ducimus deleniti, reprehenderit officiis amet, minus nihil quisquam a
        cupiditate incidunt. Ipsam sit sed debitis magni asperiores provident
        explicabo facere optio ullam porro quisquam nulla sint recusandae
        tempora facilis doloribus, vitae mollitia, nemo quaerat perferendis! Ab
        illum eum reiciendis? Veniam ex nostrum magnam blanditiis fugiat
        molestiae dignissimos. Autem ipsam quaerat sunt, veniam provident quia
        cumque nam repudiandae.
      </p>
    </>
  );
};

export default HomePage;
